@use '../node_modules/sass-mq' as *;

.t-anim-1 {
  &.t-anim-self,
  *[class*='t-anim-elem-'] {
    @include mq($from: xlarge) {
      opacity: 0;
      transform: translateY(130px);
    }
  }

  &.is-in-view-first {
    &.t-anim-self {
      animation: anim1 400ms ease-in-out forwards;
    }

    *[class*='t-anim-elem-'] {
      animation: anim1 750ms ease-in-out forwards;
    }

    $animationStep: 150ms;
    @for $i from 1 through 50 {
      .t-anim-elem-#{$i} {
        animation-delay: $animationStep + $animationStep * $i;
      }
    }
  }
}

@keyframes anim1 {
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
