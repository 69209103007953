@use '../node_modules/sass-mq' as * with (
  $breakpoints: (
        small: 380px,
        medium: 768px,
        large: 1025px,
        xlarge: 1280px,
        xmlarge: 1430px,
        xxlarge: 1700px,
  )
);

$enable-negative-margins: true;
$container-max-widths: (
        sm: 540px,
        medium: 720px,
        large: 960px,
        xlarge: 1140px,
        xmlarge: 1320px,
        xxlarge: 1400px
);
$grid-breakpoints: (
        xs: 0,
        small: 380px,
        medium: 768px,
        large: 1025px,
        xlarge: 1280px,
        xmlarge: 1430px,
        xxlarge: 1500px
);


$red: #D07D5E;
$green: #a5d1ba;

@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/hamburgers/_sass/hamburgers/hamburgers";
@import "../node_modules/swiper/swiper";
@import "../node_modules/swiper/modules/pagination";
@import "../node_modules/swiper/modules/effect-fade";
@import "animation";
@import url("https://use.typekit.net/epc2oho.css");
html {
  scroll-behavior: smooth;

}

html.lenis, html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}

body {
  font-family: "mendl-sans-dusk", sans-serif;

  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-weight: 300;
  transition: 1000ms all ease-in-out;

  &.is-red {
    background: $red
  }

  &.has-fullpage > div:not(#fullpage) {
    max-height: 0;
    max-width: 0;
    overflow: hidden;
  }
}

.fp-watermark {
  display: none !important;
}

.section {
  width: 100%;
  @include mq($from: xlarge) {
    //height: 100vh;

  }

  .fp-overflow {
    @include mq($from: xlarge) {
      overflow: visible;
    }
  }
}

.c-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  background: white;
  right: 0;
  display: flex;
  transition: opacity 500ms ease-in-out;
  justify-content: space-between;
  box-shadow: 1px 1.5px 6px rgba(90, 90, 90, 0.16);

  padding: 10px;
  opacity: 0;
  @include mq($from: xlarge) {
    padding: 15px;
  }

  &.is-visible {
    opacity: 1;
  }

  &__logo {
    width: 120px;
  }
}

.c-menu-bt {
  padding: 10px;
  z-index: 310;
  @include mq($from: xlarge) {
    display: none;
  }
}

.c-menu {
  display: flex;
  align-items: center;
  transition: all 1s ease-in-out;

  @include mq($from: xlarge) {
    gap: 70px;
  }
  @include mq($until: xlarge) {

    transform: translateY(-100%);
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: white;
    z-index: 305;
    flex-direction: column;
    justify-content: space-around;
  }

  &.is-active {
    transform: translateY(0);
  }

  &__logo {
    width: 60vw;
    @include mq($from: xlarge) {
      display: none;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 30px;
    @include mq($from: xlarge) {
      flex-direction: row;
      gap: 70px;
    }

    &-item {
      font-weight: 300;
      font-size: 18px;
      text-align: center;
      position: relative;

      &-link {
        display: block;
        color: #000;
        text-decoration: none;
        padding: 0 0 6px;
        background: var(--item-bg, url('/images/menu-inactive.svg') no-repeat center bottom);
      }

      &.active,
      &.is-active {
        --item-bg: none;

        &::after {
          position: absolute;
          content: '';
          width: 0;

          height: 2px;
          border-radius: 3px;
          background: black;
          display: block;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          animation: showMenuActive 500ms ease-in-out forwards;
        }
      }
    }

  }

  &__socials {
    display: flex;
    gap: 25px;

    a {
      text-decoration: none;
    }
  }
}

@keyframes showMenuActive {
  to {
    width: 100%;
  }

}

.c-lang-switch {
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  color: #000;

  @include mq($from: xlarge) {
    font-size: 18px;

  }

  a {
    color: #000;
    text-decoration: none;

    &.is-active {
      font-weight: bold;
    }
  }
}

ol,
ul,
p {

  font-size: 16px;
  letter-spacing: 0.03em;
  line-height: 24px;
  font-weight: 200;
  text-align: center;
  color: #000;

  @include mq($from: xlarge) {
    line-height: 28px;
    font-size: 18px;
    text-align: left;

  }

}

ol,
ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

section {
  @include mq($from: xlarge) {
    min-height: 100vh;
  }
}


.c-main-logo {
  position: absolute;
  left: 50%;
  pointer-events: none;
  top: 75px;
  z-index: 10;
  transform: translateX(-50%);
  @include mq($from: xlarge) {
    top: 120px;
  }

  &__img {
    width: 60vw;
    @include mq($from: xlarge) {
      width: 350px;
    }
  }
}

#before-after {
  position: relative;
  z-index: 25;

}

.c-before-after {
  position: relative;
  width: 100%;
  height: 100vh;
  user-select: none;
  overflow-x: hidden;

  @include mq($until: xlarge) {
    height: 80vh;
  }

  &__image {

    user-select: none;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &_new {
      clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
      animation: reveal 3s forwards 1s;
    }
  }

  &__handle {
    position: absolute;
    top: 0;
    left: 80%;
    opacity: 0;
    width: 10px;
    height: 100%;
    margin-left: -5px;
    cursor: ew-resize;
    animation: animateHandle 500ms forwards 3.5s;

    &::after {
      pointer-events: none;
      position: absolute;
      top: 90%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '';
      display: block;
      width: 66px;
      height: 66px;
      background: url(/images/drag-handle.svg);
      @include mq($from: xlarge) {
        top: 50%;
      }
    }
  }
}

@keyframes reveal {
  from {
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
  }
  to {
    clip-path: polygon(0 0, 80% 0, 80% 100%, 0 100%);
  }
}

@keyframes animateHandle {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.c-entry {
  position: relative;
  z-index: 11;
  padding: 120px 0 45px;
  @include mq($from: xlarge) {
    padding: 0;
  }
  //display: flex;
  //align-items: center;
  //justify-content: center;

  &__image-1 {
    max-width: 50%;
    @include mq($from: xlarge) {
      max-width: 370px;
      max-height: 60vh;
    }
  }

  &__text-1 {
    margin-top: 40px;
    color: #000;

    @include mq($from: xlarge) {
      margin-top: 25px;
    }

  }
}


.c-horizontal {
  //display: grid;
  //grid-template-areas: 'content';

  //@include mq($from: xlarge) {
  //  height: 450vh;
  //}
  position: relative;

  &__text {
    @include mq($until: xlarge) {
      text-align: center;
    }
  }

  &__menu {
    grid-area: content;
    position: absolute;
    top: 80px;
    z-index: 5;
    width: 100vw;
    //height: calc(100vh - 120px);
    box-shadow: 0px -50px 0px 0px rgba(255, 255, 255, 1);

    @include mq($until: xlarge) {
      overflow: hidden;
      position: sticky;
      border-top: solid 10px white;
      background: white;
    }
    @include mq($from: xlarge) {
      top: 120px;

    }

    &-list {

      display: flex;
      gap: 20px;
      padding-bottom: 20px;

      @include mq($until: xlarge) {
        background: white;
        justify-content: space-evenly;
        width: 150%;
        margin-left: 25vw;
        transition: all ease-in-out 500ms;
      }

      &[data-index="1"] {

        @include mq($until: xlarge) {

          margin-left: -25%;
        }
      }

      &[data-index="2"] {

        @include mq($until: xlarge) {

          margin-left: -75%;
        }
      }

    }

    &-item {
      overflow: hidden;
      font-weight: 300;
      font-size: 12px;
      text-align: left;
      color: #000;
      opacity: 0.5;
      text-transform: uppercase;
      padding-bottom: 10px;
      cursor: pointer;
      background: url(/images/horizontal-menu-inactive.svg) bottom center no-repeat;

      @include mq($until: xlarge) {
        width: 60vw;
        text-align: center;
      }

      a {
        text-decoration: none;
        color: currentColor;
      }

      &.is-active {
        opacity: 1;
        font-weight: 500;
        background-image: url(/images/horizontal-menu-active.svg);
      }
    }
  }

  &__section-inner {
    display: grid;
    grid-template-areas: 'text' 'image';
    @include mq($until: xlarge) {
      grid-template-rows: auto 425px;
      //height: calc(100vh - 150px);
      margin-top: auto;
    }

    @include mq($from: xlarge) {
      grid-template-areas: 'text image';
      padding-bottom: 25px;
    }
    @include mq($from: xxlarge) {
      padding-bottom: 50px;
    }
  }

  &__text {
    grid-area: text;
    //@include mq($until: xlarge) {
    //  margin: 100px auto auto !important;
    //}
  }

  &__section-img {
    grid-area: image;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /*

  .fp-slide {
    height: calc(100vh - 100px);
    padding-top: 100px;

  }
  */
  .fp-arrow {
    display: none;
  }

  &__section {
    //max-height: calc(100vh - 100px);
    @include mq($until: xlarge) {
      padding: 50px 0 100px;
    }
    @include mq($from: xlarge) {
      height: 100vh;
    }
    display: flex;
    align-items: flex-end;

    &-1 {
      .c-horizontal__section-inner {
        gap: 12vw;
        @include mq($from: xlarge) {
          grid-template-columns: 1fr 1.5fr;
          //min-height: 90vh;
        }
        @include mq($until: xlarge) {
          grid-template-rows: auto 325px;
        }
      }

      &-img {
        @include mq($from: xlarge) {
          margin-top: auto;
          height: calc(100vh - 125px);
        }
        @include mq($from: xxlarge) {
          height: calc(100vh - 150px);
        }
      }

      .c-horizontal__text {
        margin: 0 auto auto;

        @include mq($from: xlarge) {
          margin: auto auto 0;
          max-width: 425px;
        }
      }

    }

    &-2 {
      .c-horizontal__section-inner {
        gap: 10vw;

        @include mq($from: xlarge) {
          grid-template-areas: 'image text';
          grid-template-columns: 3fr 1fr;
          min-height: 90vh;
          padding-top: 15vh;
        }
      }

      .c-horizontal__text {
        @include mq($from: xlarge) {
          margin: auto auto 100px 0;
        }
      }


      &-img {
        @include mq($from: xlarge) {
          height: 63vh;
          margin-top: auto;
        }
        @include mq($from: xxlarge) {
          height: 68vh;
        }
      }
    }

    &-3 {
      .c-horizontal__section-inner {
        gap: 10vw;
        @include mq($from: xlarge) {
          grid-template-columns: 1fr 2fr;
          min-height: 90vh;
        }
      }

      &-img {
        @include mq($from: xlarge) {
          height: 90vh;
        }
      }

      .c-horizontal__text {
        @include mq($from: xlarge) {
          margin: auto 0 100px auto;
          max-width: 300px;
        }
      }

    }
  }

  &__list {
    //margin-bottom: auto;
    grid-area: content;
    //display: flex;
    @include mq($from: xlarge) {
      //position: sticky;
      //height: 100vh;
    }
    //top: 0;
    @include mq($until: xlarge) {
      //flex-direction: column;
    }

    &-item {

      display: flex;
      //width: 100vw;
      @include mq($from: xlarge) {
        //height: 100vh;
      }
      flex: 0 0 auto;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
}

* {
  -webkit-font-smoothing: antialiased;
}

.c-numbers {
  color: white;
  text-align: center;

  @include mq($from: xlarge) {
    text-align: left;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    @include mq($until: xlarge) {
      padding: 0;
    }
    @include mq($from: xlarge) {
      flex-direction: row-reverse;
      justify-content: space-evenly;
      align-items: center;
      gap: 135px;
      padding-top: 100px;
    }

    > div {
      @include mq($from: xlarge) {
        flex: 1;
      }
    }
  }

  &__item {
    margin-bottom: 35px;
  }

  &__image {
    flex: 1;
    margin-bottom: 25px;
    @include mq($from: xlarge) {
      height: calc(100vh - 125px);
    }
    @include mq($from: xxlarge) {
      height: calc(100vh - 150px);

      margin-bottom: 50px;
    }

    @include mq($until: xlarge) {
      margin: 50px calc(-0.5 * var(--bs-gutter-x)) 0;
    }

    &-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.c-growth {


  color: white;
  text-align: center;

  @include mq($from: xlarge) {
    text-align: left;
  }

  &__inner {
    @include mq($until: xlarge) {
      padding: 100px 0;
    }
    @include mq($from: xlarge) {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-evenly;
      gap: 135px;
    }
  }

  &__title {
    margin-top: auto;
    margin-bottom: 70px;
    text-align: center;
    @include mq($from: xlarge) {
      margin-bottom: 150px;

    }


    &-image {
      margin-bottom: 30px;
      max-width: 50%;
      @include mq($from: xlarge) {
        max-width: 410px;

      }
    }
  }


  &__text {

    .c-morse {
      margin-bottom: 30px;
    }
  }

  &__item {
    margin-bottom: 35px;
  }
}

h2 {

  font-weight: 300;

  strong {
    font-weight: 400;
  }
}

.c-subtitle {

  font-weight: 200;
  font-size: 24px;
  line-height: 1em;
  letter-spacing: 0.03em;
  text-align: center;
  text-transform: uppercase;


  @include mq($from: xlarge) {
    font-size: 28px;
    text-align: left;
    line-height: 1em;
    white-space: nowrap;
  }

  &_center {
    @include mq($from: xlarge) {
      text-align: center;
    }
  }

  &_wrap {
    white-space: wrap !important;
  }

  strong {
    font-weight: 400;
  }
}

.c-morse {
  max-width: 170px;
  @include mq($until: xlarge) {
    margin-left: auto;
    margin-right: auto;
  }
}

.c-data-item {
  &__line-1 {
    //font-family: "mendl-sans-down", sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    @include mq($from: xlarge) {
      text-align: left;

    }

  }

  &__line-2 {
    font-weight: 300;
    font-size: 14px;
    @include mq($from: xlarge) {
      text-align: left;

    }

  }
}

.c-number-item {

  white-space: nowrap;

  &__line-1 {
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: 300;
    font-size: 25px;
    @include mq($from: xlarge) {
      text-align: left;

    }


  }

  &__line-2 {
    font-weight: 300;
    font-size: 14px;
    @include mq($from: xlarge) {
      text-align: left;

    }


  }
}

.c-experts {
  //padding: 120px 0 0;
  text-align: center;
  --swiper-pagination-bullet-inactive-color: white;
  --swiper-pagination-color: white;
  --swiper-pagination-bullet-active-color: white;
  --swiper-pagination-bullet-horizontal-gap: 15px;

  .swiper-pagination-bullet {
    position: relative;
    width: 50px;
    border-radius: 100%;
    height: 2px;

    &::before,
    &::after {
      width: 4px;
      aspect-ratio: 1;
      background: white;
      border-radius: 100%;
      content: '';
      display: block;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
    }

    &::before {
      left: -10px;
    }

    &::after {
      right: -10px;
    }
  }

  .swiper-slide {
    height: auto;
    position: relative;
  }

  @include mq($from: xlarge) {
    text-align: left;
    //padding: 150px 0;
  }

  &__morse {
    margin-bottom: 30px;
  }

  &__title {
    margin-bottom: 60px;
    @include mq($until: xlarge) {
      margin-top: 100px;
    }
    @include mq($from: xlarge) {
      margin-top: 100px;
      margin-bottom: 80px;
    }
  }

  &__logo {
    height: 55px;
    margin-top: 20px;
  }

  &__p {

    font-weight: 200;
    font-size: 14px;
    letter-spacing: 0.02em;
    line-height: 22px;
    text-align: center;
    color: #040809;

    @include mq($from: xlarge) {
      font-size: 16px;
      line-height: 24px;
      text-align: left;
    }

  }

  &__image-title {
    position: absolute;
    left: 50%;
    top: 20px;
    transform: translateX(-50%);
    font-size: 14px;
    z-index: 10;
  }

  &__swiper {
    height: 100%;
  }

  &__image {
    position: relative;
    @include mq($until: xlarge) {
      margin: 70px calc(-0.5 * var(--bs-gutter-x)) 0;
    }
    @include mq($from: xlarge) {
      height: calc(100vh - 150px);
      margin-top: 100px;
    }

    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.c-footer {
  background: #000000;
  color: white;
  text-align: center;
  @include mq($from: xlarge) {
    text-align: left;
  }

  &__wrapper {
    padding: 120px 0 80px;
  }

  &__inner {
    margin-bottom: 80px;
    @include mq($from: xlarge) {
      display: grid;
      grid-template-columns: 1fr 0.5fr 0.5fr;
      margin-bottom: 180px;
      gap: 80px 100px;
      grid-template-areas: 'logo block1 block2' 'logo block3 block3';
    }
  }

  &__logo {
    grid-area: logo;

    @include mq($until: xlarge) {
      margin-bottom: 60px;
    }

    &-img {
      width: 60vw;

      margin-bottom: 30px;
      @include mq($from: xlarge) {
        width: 370px;
      }
    }
  }

  &__block-1 {
    grid-area: block1;
  }

  &__block-2 {
    grid-area: block2;
  }

  &__block-3 {
    grid-area: block3;
  }

  &__copy {
    border-top: 1px solid #fff;
    padding-top: 60px;

    text-align: center;
    @include mq($from: xlarge) {
      text-align: left;
    }

    a {
      color: currentColor;
      text-decoration: none;
    }

    &-1 {
      font-weight: 200;
      font-size: 16px;
      letter-spacing: 0.02em;
      line-height: 24px;

    }

    &-2 {
      font-weight: 200;
      font-size: 11px;
      letter-spacing: 0.02em;
      line-height: 24px;
      color: #fff;

      a {
        color: white;
      }

    }
  }

  &__logo2 {
    width: 170px;
  }
}

.c-contact-block {
  text-align: center;
  font-weight: 200;
  @include mq($until: xlarge) {
    margin-bottom: 70px;
  }
  @include mq($from: xlarge) {
    text-align: left;
  }

  &__line-1 {
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0.2em;
    color: $green;
    text-transform: uppercase;
    margin-bottom: 24px;
    @include mq($from: xlarge) {
      margin-bottom: 32px;
    }
  }

  &__line-2 {
    font-family: "mendl-sans-down", sans-serif;

    text-transform: uppercase;
    font-weight: 300;
    font-size: 25px;
    letter-spacing: 0.05em;
    line-height: 30px;
    margin-bottom: 20px;

  }

  &__line-3 {
    font-weight: 300;
    font-size: 20px;
    letter-spacing: 0.05em;
    line-height: 30px;

    a {
      color: currentColor;
      text-decoration: none;
    }
  }
}

.c-text-section {
  padding: 100px 0;

  ol,
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: disc;
  }

  ol, ul {
    padding-left: 1em;
  }
}
